<template>
  <div class="mt-4">
    <v-card>
      <v-card-text class="d-flex">
        <router-link to="/validasi-validasi">
          <v-btn icon color="grey"><v-icon>mdi-arrow-left</v-icon></v-btn>
        </router-link>
        <v-spacer></v-spacer>
      </v-card-text>
      <v-container>
        <v-card-title
          class="text-uppercase primary--text text-h4 font-weight-black"
        >
          {{ title }}
        </v-card-title>
        <v-card-subtitle
          class="text-h6 font-weight-light"
          v-if="itemModified.description !== '' && itemModified.nip !== ''"
        >
          <v-row>
            <v-col cols="6">
              {{ itemModified.description }} -
              <span class="font-weight-bold">{{ itemModified.nip }}</span>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn
                color="primary"
                @click="buttonReloadItemsClick"
                small
                outlined
                ><v-icon small class="mr-1">mdi-sync</v-icon>Refresh</v-btn
              >
            </v-col>
          </v-row>
          <div class="grey--text text-subtitle-2 font-weight-light mb-4">
            {{ lookupItemFDivision(itemModified.fdivisionBean).description }}
          </div>
          <div
            class="text-subtitle-2 font-weight-regular"
            v-if="!isJenisProsedurFalse"
          >
            Prosedur :
            <span>{{ jenisProsedur }}</span>
          </div>
          <div
            class="text-subtitle-2 font-weight-regular"
            v-if="!isJenisKenaikanPangkatFalse"
          >
            Kenaikan Pangkat :
            <span
              >{{ jenisKenaikanPangkat }} - {{ itemModified.periodeYear }}/{{
                lookupPeriodeMonth(itemModified.periodeMonth).description
              }}</span
            >
          </div>
        </v-card-subtitle>
      </v-container>
      <v-card-text>
        <v-container class="pa-2">
          <v-data-table
            :headers="headers"
            :items="itemsFtRegistrasiFile"
            :page.sync="currentPage"
            :items-per-page="pageSize"
            hide-default-footer
            class="elevation-0"
            @page-count="totalTablePages = totalPaginationPages"
          >
            <template v-slot:[`item.description`]="{ item }">
              <div
                v-if="item.fileName !== ''"
                @click="showPdfViewerDialog(item)"
                class="hand-cursor"
              >
                <span>
                  <v-icon color="success" small class="mr-1"
                    >mdi-file-document</v-icon
                  ></span
                >
                <span>
                  {{ item.description }}
                </span>
                <span v-if="item.mandatory">
                  <v-icon color="red" x-small>mdi-star-circle</v-icon>
                </span>
              </div>
              <div v-if="item.fileName === ''">
                <span>
                  <v-icon small class="mr-1">mdi-file-document</v-icon></span
                >
                <span>
                  {{ item.description }}
                </span>
                <span v-if="item.mandatory">
                  <v-icon color="red" x-small>mdi-star-circle</v-icon>
                </span>
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-switch
                @change="saveOnInputStatusValid(item, item.statusValid)"
                class="text-h6"
                :disabled="item.mandatory === false && item.fileName === ''"
                small
                inset
                v-model="item.statusValid"
                ><template v-slot:[`label`]>
                  <span class="subtitle-2">
                    {{
                      item.statusValid ? "Berkas Valid" : "Berkas Tidak Valid"
                    }}
                  </span>
                </template></v-switch
              >
            </template>
            <template v-slot:[`item.alasan`]="{ item }">
              <div class="py-3">
                <v-autocomplete
                  @input="saveOnInputAlasan(item, item.statusAlasan)"
                  outlined
                  :disabled="
                    item.statusValid === true ||
                    (item.mandatory === false && item.fileName === '')
                  "
                  v-model="item.statusAlasan"
                  :items="itemsAlasanStatus"
                  item-value="id"
                  item-text="description"
                  auto-select-first
                  dense
                  color="blue-grey lighten-2"
                  label="Alasan"
                  hide-details=""
                ></v-autocomplete>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-container class="mb-0 pb-0">
        <v-card-text class="mb-0 pb-0 text-h5 font-weight-bold">
          Hasil Verifikasi
        </v-card-text>
        <v-card-text class="mb-0 pb-0"> Tuliskan Komentar </v-card-text>
        <v-card-text class="mb-0 pb-0">
          <v-textarea
            @blur="saveOnInputAlasan"
            v-model="itemModified.approvedReasons"
            auto-grow
            outlined
            placeholder="Pesan yang akan dikirimkan kepada pengusul oleh verifikator berkenaan dengan dokumen yang dikirimkan"
          ></v-textarea>
        </v-card-text>
      </v-container>
      <v-container>
        <v-card-actions class="px-4 mb-4">
          <v-row align="center">
            <v-col cols="2">
              <v-btn
                @click="confirmAndVerifyDocumentRegistrasi"
                :disabled="buttonValidDisabled"
                color="primary"
              >
                <v-icon left dark class="mr-1"> mdi-check-circle </v-icon>
                Valid
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" align="end">
              <v-btn
                @click="rejectDocumentRegistrasi"
                :disabled="
                  buttonRejectDisabled && !currentUser.roles.includes(ERole.ROLE_USER)
                "
                class="white--text"
                color="red"
                >Tidak Valid</v-btn
              >
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                :disabled="
                  buttonRejectDisabled && !currentUser.roles.includes(ERole.ROLE_USER)
                "
                outlined
                v-model="validasiReasonCodeDefault"
                :items="itemsDitolak"
                item-value="id"
                item-text="description"
                auto-select-first
                dense
                color="blue-grey lighten-2"
                label="Alasan"
                hide-details=""
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-container>
    </v-card>
    <PdfViewerDialog ref="refPdfViewerDialog"></PdfViewerDialog>
    <ValidConfirmDialog
      ref="refValidConfirmDialog"
      @eventFromValidConfirmDialog="confirmValid"
    ></ValidConfirmDialog>
    <RejectConfirmDialog
      ref="refRejectConfirmDialog"
      @eventFromRejectConfirmDialog="confirmReject"
    ></RejectConfirmDialog>
  </div>
</template>

<script>
import FileService from "@/services/apiservices/file-service";
import FtRegistrasiFileService from "@/services/apiservices/ft-registrasi-file-service";
import FtRegistrasiService from "@/services/apiservices/ft-registrasi-service";
import ValidConfirmDialog from "@/components/utils/ValidConfirmDialog.vue";
import RejectConfirmDialog from "@/components/utils/RejectConfirmDialog.vue";

import { EAlasanStatuses } from "@/models/e-alasan-status";
import { EDitolaks } from "@/models/e-ditolak";

import { format, parseISO } from "date-fns";
import PdfViewerDialog from "@/components/utils/BsdPdfViewerDialog.vue";
import FTemplateRegDokumenService from "@/services/apiservices/f-template-reg-dokumen-service";
import ApiFonnteService from "@/services/apiservices-ext/api-fonnte-service";
import ERole from "@/models/e-role";
import FtRegistrasiLog from "@/models/ft-registrasi-log";
import ERegistrasiLog, {ERegistrasiLogs} from "@/models/e-registrasi-log";
import FtRegistrasiLogService from "@/services/apiservices/ft-registrasi-log-service";

export default {
  name: "ValidasiVerifikasiDoc",
  components: {
    PdfViewerDialog,
    ValidConfirmDialog,
    RejectConfirmDialog,
  },
  props: {},
  data() {
    return {
      jenisProsedur: "",
      jenisKenaikanPangkat: "",
      title: "VERIFIKASI",
      snackbar: false,
      snackBarMesage: "",
      itemsDitolak: EDitolaks,
      itemsAlasanStatus: EAlasanStatuses,

      transparent: "rgba(255, 255, 255, 0)",

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 50,
      pageSizes: [10, 25, 50, 150, 500],

      itemsMonth: [
        // { id: 1, description: "Januari" },
        { id: 2, description: "Februari" },
        // { id: 3, description: "Maret" },
        { id: 4, description: "April" },
        // { id: 5, description: "Mei" },
        { id: 6, description: "Juni" },
        // { id: 7, description: "Juli" },
        { id: 8, description: "Agustus" },
        // { id: 9, description: "September" },
        { id: 10, description: "Oktober" },
        // { id: 11, description: "November" },
        { id: 12, description: "Desember" },
      ],
      search: "",
      headers: [
        {
          text: "Deskripsi",
          align: "start",
          sortable: true,
          value: "description",
          width: "40%",
        },
        { text: "Aksi", value: "actions", width: "23%", sortable: false },
        {
          text: "Pilih Alasan",
          value: "alasan",
          width: "37%",
          sortable: false,
        },
      ],

      formMode: "",
      itemSelectedIndex: -1,
      itemSelected: "",
      currentFtRegistrasiFileBean: undefined,
      itemsFtRegistrasiFile: [],

      buttonValidDisabled: true,
      buttonRejectDisabled: true,

      itemsERegistrasiLog: ERegistrasiLogs,
      ERegistrasiLog: ERegistrasiLog
    };
  },
  computed: {
    ERole() {
      return ERole;
    },
    isJenisProsedurFalse() {
      return (
        this.lookupFTemplateReg(this.itemModified.ftemplateRegBean)
          .showJenisProsedur === true &&
        this.itemModified.fjenisProsedurBean === 0
      );
    },
    isJenisKenaikanPangkatFalse() {
      return (
        this.lookupFTemplateReg(this.itemModified.ftemplateRegBean)
          .showJenisKenaikanPangkat === true &&
        this.itemModified.fjenisKenaikanPangkatBean === 0
      );
    },
    validasiReasonCodeDefault: {
      get() {
        if (this.itemModified.validasiReasonCode === 0) {
          return 2;
        } else {
          return this.itemModified.validasiReasonCode;
        }
      },
      set(value) {
        // Add logic here if needed when setting the value
        this.itemModified.validasiReasonCode = value;
      },
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    itemsUser: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsUser;
      },
      set(value) {
        this.$store.dispatch("FtRegistrasiModule/updateItemsUser", value);
      },
    },
    itemDefault: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemDefault;
      },
      set(val) {
        this.$store.dispatch("FtRegistrasiModule/updateItemDefault", val);
      },
    },
    itemModified: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemModified;
      },
      set(val) {
        this.$store.dispatch("FtRegistrasiModule/updateItemModified", val);
      },
    },

    itemsFTemplateRegDokumen() {
      return this.$store.state.FtRegistrasiModule.itemsFTemplateRegDokumen;
    },
    itemsFJenisDokumen() {
      return this.$store.state.FtRegistrasiModule.itemsFJenisDokumen;
    },
    itemsFTemplateReg: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFTemplateReg;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFTemplateReg",
          value
        );
      },
    },
    itemsFJenisProsedur: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisProsedur;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisProsedur",
          value
        );
      },
    },
    itemsFJenisKenaikanPangkat: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisKenaikanPangkat;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisKenaikanPangkat",
          value
        );
      },
    },
    itemsFDivision: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFDivision;
      },
      set(value) {
        this.$store.dispatch("FtRegistrasiModule/updateItemsFDivision", value);
      },
    },
    userAdminPengusul() {
      return this.itemsUser.filter(
        (item) => item.username === this.itemModified.modifiedBy
      );
    },
  },
  watch: {
    itemsFtRegistrasiFile: {
      handler: function (val) {
        if (val) {
          this.changeButtonStatus();
        }
      },
      deep: true,
    },
  },
  methods: {
    confirmValid() {
      this.itemModified.validasiStatus = 1;
      this.itemModified.validasiReasonCode = 0;
      FtRegistrasiService.updateFtRegistrasi(this.itemModified).then(
        (response) => {
          let newItemFtRegistrasiLog = new FtRegistrasiLog()
          newItemFtRegistrasiLog.ftRegistrasiBean = response.data.id
          newItemFtRegistrasiLog.actionType = 'UPDATE'
          newItemFtRegistrasiLog.oldData = JSON.stringify(this.itemDefault)?JSON.stringify(this.itemDefault):"{}"
          newItemFtRegistrasiLog.newData = JSON.stringify(this.itemModified)?JSON.stringify(this.itemModified):"{}"
          newItemFtRegistrasiLog.remarks = this.lookupItemsERegistrasiLog(ERegistrasiLog.VALID).description
          newItemFtRegistrasiLog.modifiedBy = this.currentUser.username

          FtRegistrasiLogService.createFtRegistrasiLog(newItemFtRegistrasiLog).then(
              () => {
              },
              error => {
                console.log(error.response)
              }
          )
          this.$router.push("/validasi-validasi");
        },
        (err) => {
          console.log(err);
        }
      );
    },
    getJenisDitolak(validasiReasonCode) {
      if (validasiReasonCode === 1) return "Tidak Sesuai";
      else if (validasiReasonCode === 2) return "Tidak Memenuhi Syarat";
    },
    confirmReject() {
      this.itemModified.validasiStatus = 2;
      if (this.itemModified.validasiReasonCode === 0) {
        this.itemModified.validasiReasonCode = 2;
      }
      if (this.itemModified.validasiReasonCode === 1) {
        this.itemModified.validasiStatus = 0;
        this.itemModified.verifikatorBean = 0;
        this.itemModified.submitted = false;
      }
      FtRegistrasiService.updateFtRegistrasi(this.itemModified).then(
        (response) => {
          let newItemFtRegistrasiLog = new FtRegistrasiLog()
          newItemFtRegistrasiLog.ftRegistrasiBean = response.data.id
          newItemFtRegistrasiLog.actionType = 'UPDATE'
          newItemFtRegistrasiLog.oldData = JSON.stringify(this.itemDefault)?JSON.stringify(this.itemDefault):"{}"
          newItemFtRegistrasiLog.newData = JSON.stringify(this.itemModified)?JSON.stringify(this.itemModified):"{}"
          newItemFtRegistrasiLog.remarks = `${this.lookupItemsERegistrasiLog(ERegistrasiLog.REJECT).description} karena ${response.data.validasiReasonCode === 1 ? "Berkas Tidak Sesuai" : "Tidak Memenuhi Syarat"}`
          newItemFtRegistrasiLog.modifiedBy = this.currentUser.username

          FtRegistrasiLogService.createFtRegistrasiLog(newItemFtRegistrasiLog).then(
              () => {
              },
              error => {
                console.log(error.response)
              }
          )
          if (this.userAdminPengusul[0] !== undefined) {
            // console.log("fonte jalan");
            let pesan = `Berkas atas nama ${this.itemModified.description} - ${
              this.itemModified.nip
            } ${this.getJenisDitolak(
              this.itemModified.validasiReasonCode
            )} dan tidak dapat diproses lebih lanjut. Dikarenakan ${
              this.itemModified.approvedReasons !== ""
                ? this.itemModified.approvedReasons
                : "Tidak Ada Alasan"
            }.`;
            const simpleMessage = {
              to: this.userAdminPengusul[0].phone,
              subject: "Verifikator Silayak",
              message: pesan,
            };
            ApiFonnteService.postFonnteSendMessagePublic(simpleMessage).then(
              () => {}
            );
          }
          this.$router.push("/validasi-validasi");
        },
        (err) => {
          console.log(err);
        }
      );
    },
    confirmAndVerifyDocumentRegistrasi() {
      this.$refs.refValidConfirmDialog.showDialog(
        "Apakah ada yakin untuk Validasi Berkas?"
      );
    },
    rejectDocumentRegistrasi() {
      this.$refs.refRejectConfirmDialog.showDialog(
        "Apakah ada yakin untuk Mengembalikan Berkas?"
      );
    },
    showPdfViewerDialog(item) {
      this.$refs.refPdfViewerDialog.showDialog(item);
    },
    saveOnInputAlasan(item, statusAlasan) {
      item.statusAlasan = statusAlasan;
      FtRegistrasiFileService.updateFtRegistrasiFile(item).then(
        () => {},
        (err) => {
          console.log(err);
        }
      );
    },
    saveOnInputStatusValid(item, statusValid) {
      item.statusValid = statusValid;
      if (statusValid === true) {
        item.statusAlasan = 0;
      }
      FtRegistrasiFileService.updateFtRegistrasiFile(item).then(
        () => {},
        (err) => {
          console.log(err);
        }
      );
    },

    fetchParent() {
      // FTemplateRegService.getAllFTemplateReg().then(
      //   (response) => {
      //     this.itemsFTemplateReg = response.data;
      //   },
      //   (error) => {
      //     console.log(error.response);
      //   }
      // );
    },

    fetchFtRegistrasiFileAndUpdateStatus(ftRegistrasiBean) {
      if (ftRegistrasiBean === undefined) {
        return;
      }
      FtRegistrasiFileService.getAllFtRegistrasiFileByFtRegistrasiBeanAndFileType(
        ftRegistrasiBean,
        "DOC"
      ).then(
        (response) => {
          this.itemsFtRegistrasiFile = response.data;
          this.changeButtonStatus();
        },
        (error) => {
          console.log(error.data);
        }
      );
    },

    downloadFile(item) {
      return FileService.file_url(item.fileName);
    },

    changeButtonStatus() {
      this.buttonValidDisabled = false;
      this.buttonRejectDisabled = true;
      this.itemsFtRegistrasiFile.forEach((item) => {
        // console.log(this.lookupItemFTemplateRegDokumen(item.ftemplateRegDokumenBean).mandatory)
        if (item.mandatory === true) {
          if (item.statusValid === false) {
            this.buttonValidDisabled = true;
            this.buttonRejectDisabled = false;
            //Break
            return false;
          }
        }
      });
    },

    lookupItemFTemplateRegDokumen(ftemplateRegDokumenBean) {
      const str = this.itemsFTemplateRegDokumen.filter(
        (x) => x.id === ftemplateRegDokumenBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupItemFJenisDokumen(fjenisDokumenBean) {
      const str = this.itemsFJenisDokumen.filter(
        (x) => x.id === fjenisDokumenBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupItemFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFTemplateReg(ftemplateRegBean) {
      const str = this.itemsFTemplateReg.filter(
        (x) => x.id === ftemplateRegBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFJenisProsedur(fjenisProsedurBean) {
      const str = this.itemsFJenisProsedur.filter(
        (x) => x.id === fjenisProsedurBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupItemsERegistrasiLog(id) {
      const str = this.itemsERegistrasiLog.filter(
          (x) => x.id === id
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupPeriodeMonth(monthBean) {
      const str = this.itemsMonth.filter((x) => x.id === monthBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFJenisKenaikanPangkat(fjenisKenaikanPangkatBean) {
      const str = this.itemsFJenisKenaikanPangkat.filter(
        (x) => x.id === fjenisKenaikanPangkatBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupImageUrl(item) {
      // console.log(item.fileName);

      if (item.fileName === undefined) {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_low(item.fileName);
      }
    },
    lookupImageUrlLazy() {
      return "./assets/images/no_image_available.jpeg";
    },

    formatCreatedDate(dateCreated) {
      return format(
        parseISO(new Date(dateCreated).toISOString()),
        "dd-MMM-yyyy hh:mm:ss"
      );
    },
    buttonReloadItemsClick() {
      //1. Cek apakah Jumlah  Items FtRegistrasiFile sama dengan Jumlah Document Items
      FTemplateRegDokumenService.getAllFTemplateRegDokumenByParent(
        this.itemModified.ftemplateRegBean
      ).then((response) => {
        const tempRegDokumen = response.data;
        if (tempRegDokumen.length > 0) {
          FtRegistrasiFileService.getAllFtRegistrasiFileByFtRegistrasiBeanAndFileType(
            this.itemModified.id,
            "DOC"
          ).then(
            (response) => {
              //Make sure bahwa Jumlah FtRegistrasiFile Sama
              if (response.data.length === this.itemsFtRegistrasiFile.length) {
                if (this.itemsFtRegistrasiFile.length < tempRegDokumen.length) {
                  console.log(
                    "Jumlah FtRegistrasiFile Kurang dari Jumlah Dokumen"
                  );
                  const itemIdsToAdd = [];
                  tempRegDokumen.forEach((item) => {
                    if (
                      this.itemsFtRegistrasiFile.filter(
                        (x) => x.ftemplateRegDokumenBean === item.id
                      ).length === 0
                    ) {
                      // console.log(JSON.stringify(item))
                      itemIdsToAdd.push(item.id);
                    }
                  });
                  if (itemIdsToAdd.length > 0) {
                    this.tambahkanFtRegistrasiFile(itemIdsToAdd);
                  }
                }
              }
            },
            (error) => {
              console.log(error.data);
            }
          );
        }
      });
    },

    tambahkanFtRegistrasiFile(itemIdsToAdd) {
      const payloadMulti = {
        ftRegistrasiBean: this.itemModified.id,
        fileType: "DOC",
        ftemplateRegDokumenIds: itemIdsToAdd,
      };

      FtRegistrasiFileService.createFtRegistrasiFileMulti(payloadMulti).then(
        () => {
          //Reload Ulang
          console.log("Sukses Add Additional Document");
          this.fetchFtRegistrasiFileAndUpdateStatus(this.itemModified.id);
        }
      );
    },
  },

  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      this.fetchParent();
      const id = this.$route.params.id;

      FtRegistrasiService.getFtRegistrasiById(id).then((response) => {
        this.itemModified = response.data;
        this.itemDefault = response.data;
        this.title = `VERIFIKASI ${
          this.lookupFTemplateReg(this.itemModified.ftemplateRegBean)
            .description
        }`;
        this.jenisProsedur = `${
          this.lookupFJenisProsedur(this.itemModified.fjenisProsedurBean)
            .description
        }`;
        this.jenisKenaikanPangkat = `${
          this.lookupFJenisKenaikanPangkat(
            this.itemModified.fjenisKenaikanPangkatBean
          ).description
        }`;
        //SUDAH DIWAKILI OLEH WATCH
        this.fetchFtRegistrasiFileAndUpdateStatus(id);
      });
    }
  },
};
</script>
  
<style scoped>
.hand-cursor:hover {
  cursor: pointer;
}
</style>